import { ButtonType } from '../types';

/**
 * Buttons Options
 */
export const ButtonsOptions = [
  { value: ButtonType.DOWNLOAD, label: 'Download' },
  { value: ButtonType.NAVIGATION, label: 'Navigation' },
  { value: ButtonType.ZOOM, label: 'Zoom' },
];
