/**
 * Tests Identifiers
 */
export const TestIds = {
  panel: {
    root: 'data-testid panel',
    warning: 'data-testid panel warning',
    iframe: 'data-testid panel iframe',
    video: 'data-testid panel video',
    audio: 'data-testid panel audio',
    image: 'data-testid panel image',
    imageLink: 'data-testid panel image-link',
    zoomedImage: 'data-testid panel zoomed-image',
    buttonDownload: 'data-testid panel button-download',
    buttonZoom: 'data-testid panel button-zoom',
    buttonPrevious: 'data-testid panel button-previous',
    buttonNext: 'data-testid panel button-next',
  },
};
